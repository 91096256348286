<template>
  <div class="w-full h-full flex items-center justify-center">
    <div class="login-form">
      <div class="login-form-header flex flex-row items-center justify-between">
        <h3>회원정보 수정</h3>
      </div>

      <div class="login-form-content">
        <div class="login-input-form flex flex-col">
          <label>이름</label>
          <input placeholder="이름을 입력해주세요" v-model="name" />
        </div>

        <div class="login-input-form flex flex-col">
          <label>닉네임</label>
          <input placeholder="닉네임을 입력해주세요" v-model="nickname" />
        </div>

        <div class="login-input-form flex flex-col">
          <label>휴대전화</label>
          <input placeholder="휴대전화를 입력해주세요" v-model="phone" />
        </div>

        <div class="login-input-form flex flex-col">
          <label>비밀번호</label>
          <input
            type="password"
            placeholder="비밀번호를 입력해주세요"
            v-model="password"
          />
        </div>

        <div class="login-input-form flex flex-col">
          <label>비밀번호 확인</label>
          <input
            type="password"
            placeholder="비밀번호 확인을 입력해주세요"
            v-model="password2"
          />
        </div>

        <button
          @click="onSubmit"
          class="w-full flex justify-center items-center mt-10"
        >
          수정하기
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import services from "@/services";

export default {
  name: "UserUpdate",
  data() {
    return {
      name: "",
      nickname: "",
      phone: "",
      password: "",
      password2: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const { user } = await services.userDetail(this.userId);
      if (user) {
        this.name = user.name;
        this.nickname = user.nickname;
        this.phone = user.phone;
      }
    },
    async onSubmit() {
      if (!this.name) {
        this.$toast.open({ message: "이름을 입력해주세요", type: "error" });
      } else if (!this.nickname) {
        this.$toast.open({ message: "닉네임을 입력해주세요", type: "error" });
      } else if (!this.phone) {
        this.$toast.open({ message: "휴대전화를 입력해주세요", type: "error" });
      } else if (!this.password) {
        this.$toast.open({ message: "비밀번호를 입력해주세요", type: "error" });
      } else if (!this.password2) {
        this.$toast.open({
          message: "비밀번호 확인을 입력해주세요",
          type: "error",
        });
      } else if (this.password !== this.password2) {
        this.$toast.open({
          message: "비밀번호가 일치하지 않습니다",
          type: "error",
        });
      } else {
        const data = {
          name: this.name,
          nickname: this.nickname,
          phone: this.phone,
          password: this.password,
          passwordConfirmation: this.password2,
        };

        const success = await services.userUpdate(this.userId, data);
        if (success) {
          await this.me();
          this.$toast.open("수정완료");
          this.$router.go(-1);
        } else {
          this.$toast.open({ message: "수정실패", type: "error" });
        }
      }
    },
    ...mapActions("user", ["me"]),
  },
  computed: {
    ...mapState("user", ["userId"]),
  },
};
</script>

<style scoped lang="scss">
.login-form {
  margin-top: 225px;
  margin-bottom: 130px;
  border: 1px solid #d7d7d7;
  width: 588px;
  padding-top: 75px;
  padding-bottom: 61px;

  .login-form-header {
    margin-left: 70px;
    margin-right: 66px;
    padding-bottom: 37px;
    border-bottom: 1px solid #dddee1;

    h3 {
      font-size: 30px;
      font-weight: 700;
      color: #333333;
      letter-spacing: -1.2px;
    }

    span {
      font-size: 13px;
      font-weight: 700;
      color: #636363;
      letter-spacing: -0.13px;
      margin-right: 6px;
    }

    a {
      font-size: 13px;
      font-weight: 700;
      letter-spacing: -0.13px;
      color: #283aef;
    }
  }

  .login-form-content {
    padding-left: 94px;
    padding-right: 94px;
    padding-top: 38px;

    .content-title {
      color: #333;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.56px;
      margin-bottom: 29px;
    }

    .login-input-form {
      margin-bottom: 21px;

      label {
        padding-left: 13px;
        color: rgba(40, 58, 239, 0.8);
        font-size: 15px;
        font-weight: 500;
        letter-spacing: -0.15px;
      }

      input {
        height: 44px;
        border-bottom: 1px solid #d7d7d7;
        padding-top: 13px;
        padding-bottom: 17px;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: -0.14px;
        padding-left: 14px;
        padding-right: 14px;

        &::placeholder {
          color: #dddee1;
        }
      }
    }

    button {
      background-color: #283cf0;
      border-radius: 10px;
      height: 59px;
      color: white;
      font-weight: 700;
      font-size: 16px;
      letter-spacing: -0.8px;
    }
  }

  .login-social {
    margin-top: 29px;
    padding-left: 93px;
    padding-right: 98px;

    span {
      font-size: 14px;
      color: #6e6e6e;
      font-weight: 700;
      letter-spacing: -0.14px;
    }

    .social-items {
      img {
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }

  .find-password {
    margin-top: 22px;
    padding-right: 94px;

    a {
      font-size: 14px;
      font-weight: 500;
      color: #8e8e8e;
      letter-spacing: -0.14px;
      text-decoration: underline;
    }
  }
}

@media (max-width: 639px) {
  .login-form {
    margin-top: 100px;
    margin-bottom: 50px;
    border: none;
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    .login-form-header {
      margin-left: 0;
      margin-right: 0;
      padding-bottom: 20px;
      h3 {
        font-size: 20px;
      }
      span {
        font-size: 11px;
      }
      a {
        font-size: 11px;
      }
    }
    .login-form-content {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 25px;
      .content-title {
        font-size: 12px;
      }
      .login-input-form {
        label {
          font-size: 12px;
        }
        input {
          height: 35px;
          font-size: 12px;
        }
      }
      button {
        height: 40px;
        font-size: 13px;
      }
    }
  }
}
</style>
